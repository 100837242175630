<template>
  <div>
    <div class="background">
      <div class="container">
        <div class="menu" id="contato">
          <img src="/img/front/logo_topo.png" alt="Logo Ekodata" />
        </div>
        <section class="tela-um" id="um">
          <div class="row">
            <div class="col-12 col-lg-6 centralizar">
              <h1>Soluções eficientes para Tratamento de Efluentes</h1>
              <p class="descricao">
                A Ekodata oferece tecnologia para solucionar a destinação de
                dejetos líquidos na área urbana e rural, com responsabilidade e
                respeito a todas as normas ambientais, com o melhor
                custo-benefício e máxima eficiência.
              </p>
            </div>
            <div class="col-12 col-lg-6">
              <div class="bloco-form">
                <p>
                  Preencha o formulário que entraremos em contato para entender
                  como ajudar você.
                </p>
                <div class="bloco-input" :class="this.erros.nome">
                  <input placeholder=" " v-model="form.nome" name="nome" />
                  <label>Nome</label>
                </div>
                <div class="bloco-input" :class="this.erros.email">
                  <input placeholder=" " v-model="form.email" name="email" />
                  <label>E-mail</label>
                </div>
                <div class="inputs-alinhados">
                  <div class="bloco-input" :class="this.erros.cidade">
                    <input
                      placeholder=" "
                      v-model="form.cidade"
                      name="Cidade"
                    />
                    <label>Cidade</label>
                  </div>
                  <div class="bloco-input" :class="this.erros.telefone">
                    <the-mask
                      placeholder=" "
                      v-model="form.telefone"
                      name="telefone"
                      :masked="true"
                      :mask="['(##) #####-####', '(##) ####-####']"
                    ></the-mask>
                    <label>Telefone</label>
                  </div>
                </div>
                <div class="bloco-input" :class="this.erros.mensagem">
                  <textarea
                    placeholder=" "
                    v-model="form.mensagem"
                    name="mensagem"
                  />
                  <label>Mensagem</label>
                </div>
                <!-- <div class="centralizar">
                  <div
                    class="g-recaptcha"
                    data-sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    ref="recap"
                  ></div>
                </div> -->
                <div class="erros" v-if="erroFrase.length">
                  <ul>
                    <li v-for="erro in erroFrase" :key="erro">{{ erro }}</li>
                  </ul>
                </div>
                <button
                  @click.prevent="validaForm()"
                  :disabled="btnAvancar.disabled"
                  v-html="btnAvancar.text"
                ></button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <section class="tela-dois">
      <div class="container">
        <div class="bloco-verde">
          <h2>Especialista em tratamento de efluentes suínos</h2>
          <p>
            Após mais de 11 anos de pesquisa científica, testes e validação na
            prática, chegamos a uma solução viável, eficaz e assertiva para o
            tratamento de efluentes suínos: o Sistrates.
          </p>
          <h3>Sobre o Sistrates</h3>
          <p class="sobre">
            O serviço auxilia suinocultores a reciclar a água dos dejetos
            animais, permitindo o reuso dela para fins não potáveis ou o retorno
            dela a córregos e rios sem agressão à natureza.
          </p>
          <h3>Fonte de renda extra</h3>
          <p class="renda">
            Com o tratamento, a parte sólida pode ser utilizada como adubo
            orgânico na agricultura ou fonte de renda extra, comercializando
            como fertilizante.
          </p>
          <p class="ajudar">
            Preencha o formulário para entendermos como podemos te ajudar
          </p>
          <a href="#contato">
            <button>Solicitar contato</button>
          </a>
          <div class="bloco-imgd">
            <img src="/img/front/img.png" alt="Paisagem" class="paisagem" />
            <img
              src="/img/front/detalhe_azul.png"
              alt="Detalhe"
              class="detalhe-azul"
            />
            <img
              src="/img/front/detalhe_escuro.png"
              alt="Detalhe"
              class="detalhe-escuro"
            />
          </div>
        </div>
        <div class="bloco-img">
          <img
            src="/img/front/img_completa.png"
            alt="Paisagem"
            class="img-completa"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        nome: "",
        telefone: "",
        email: "",
        cidade: "",
        mensagem: "",
      },
      erroFrase: [],
      erros: {
        nome: "",
        email: "",
        cidade: "",
        telefone: "",
        mensagem: "",
      },
      btnAvancar: {
        text: "Solicitar contato",
        disabled: false,
      },
    };
  },
  methods: {
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validaForm() {
      this.erroFrase = [];
      if (!this.form.nome) {
        this.erros.nome = "bloco-input-erro";
        this.erroFrase.push("O nome é obrigátorio.");
      }
      if (!this.form.telefone) {
        this.erros.telefone = "bloco-input-erro";
        this.erroFrase.push("O telefone é obrigátorio.");
      }
      if (!this.form.email) {
        this.erros.email = "bloco-input-erro";
        this.erroFrase.push("O e-mail é obrigátorio.");
      } else if (!this.validEmail(this.form.email)) {
        this.erros.email = "bloco-input-erro";
        this.erroFrase.push("Insira um e-mail válido");
      }
      if (!this.form.cidade) {
        this.erros.cidade = "bloco-input-erro";
        this.erroFrase.push("O cidade é obrigátorio.");
      }
      if (!this.form.mensagem) {
        this.erros.mensagem = "bloco-input-erro";
        this.erroFrase.push("A mensagem é obrigátoria.");
      }
      if (
        this.form.nome &&
        this.form.telefone &&
        this.form.mensagem &&
        this.validEmail(this.form.email) &&
        this.form.cidade
      ) {
        this.submitForm();
      }
    },
    resetForm() {
      this.form.nome = "";
      this.form.email = "";
      this.form.telefone = "";
      this.form.cidade = "";
      this.form.mensagem = "";
      this.erros.nome = "";
      this.erros.email = "";
      this.erros.telefone = "";
      this.erros.cidade = "";
      this.erros.mensagem = "";
    },
    submitForm() {
      this.btnAvancar.text = "Enviando...";
      this.btnAvancar.disabled = true;
      axios
        .post("/envia-email", this.form)
        .then((response) => {
          var msg = "Nossa equipe entrará em contato"
          this.$swal("Enviado :)", msg, "success")
          this.resetForm();
        })
        .catch((e) => {
          this.$swal("Oops!", e.response.data, "error")
        })
        .finally(() => {
          this.btnAvancar.text = "Solicitar contato"
          this.btnAvancar.disabled = false
        });
    },
  },
};
Vue.config.devtools = false;
Vue.config.productionTip = false
</script>
