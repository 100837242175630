var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.products),function(item,index){return _c('div',{key:index,staticClass:"cart__item"},[_c('div',{staticClass:"cart__info",style:('background-color:' +
        item.color +
        (_vm.width <= 880 ? ' !important' : '') +
        ';')},[_c('div',{style:('background-color:' + item.color + ';')},[_vm._v("\n        "+_vm._s(item.title)+"\n        "),_c('button',{attrs:{"id":'exclude' + item.p_id},on:{"click":function($event){return _vm.removeProduct(index)}}},[_c('i',{staticClass:"fa fa-close"})])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('span',[_vm._v("\n          "+_vm._s((item.total_price * item.quantity).toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            }))+"\n        ")]),_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(item.quantity)+" "),_c('span',[_vm._v("passaportes de")]),_vm._v(" "),_c('span',[_vm._v("X")]),_vm._v(" R$"+_vm._s(item.total_price.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            }))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"cart__buy-more"},[_c('button',{attrs:{"id":'minus' + item.p_id,"disabled":item.minus_disabled},on:{"click":function($event){return _vm.subtract(index)}}},[_c('i',{staticClass:"fa fa-minus"})]),_vm._v(" "),_c('input',{attrs:{"type":"text","disabled":""},domProps:{"value":item.quantity}}),_vm._v(" "),_c('div',{attrs:{"id":'spin' + item.p_id}},[_c('i',{staticClass:"fa fa-spinner fa-pulse"})]),_vm._v(" "),_c('button',{attrs:{"id":'plus' + item.p_id,"disabled":item.plus_disabled},on:{"click":function($event){return _vm.add(index)}}},[_c('i',{staticClass:"fa fa-plus"})])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }