var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex d-row justify-content-between sell-options"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.seller_selected),expression:"seller_selected"}],staticClass:"form-control mr-3",attrs:{"type":"text","name":"seller_selected","id":"seller_selected"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.seller_selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.intermediateSelect()}]}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Todas as Vendas")]),_vm._v(" "),_vm._l((_vm.sellers_list),function(seller,index){return _c('option',{key:index,domProps:{"value":seller.seller_id}},[_vm._v("\n        "+_vm._s(seller.seller_name)+"\n      ")])})],2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"E-mail, Ano do Evento, Código ou Nome"},domProps:{"value":(_vm.search)},on:{"blur":function($event){return _vm.callback()},"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),_vm._v(" "),_c('div',{staticStyle:{"padding":"10px 15px 10px 15px"}},[_vm._l((_vm.sells),function(item,index){return _c('div',{key:index,staticClass:"d-flex row list-box"},[_c('div',[_c('span',{staticClass:"col-12"},[_c('b',[_vm._v("#"+_vm._s(item[0].cart_id))]),_vm._v(" - "+_vm._s(item[0].client_email))]),_c('span',{class:item[0].step == 4
              ? 'badge badge-primary'
              : item[0].step == 5
              ? 'badge badge-success'
              : 'badge badge-danger'},[_vm._v(_vm._s(item[0].step == 4
              ? "Aguardando Pagamento"
              : item[0].step == 5
              ? "Pagamento Confirmado"
              : "Cancelado"))])]),_vm._v(" "),_c('span',{staticClass:"col-12 mt-3"},[_vm._v(_vm._s(item[0].client_name))]),_vm._v(" "),_c('span',{staticClass:"col-12"},[_vm._v(_vm._s(item[0].created))]),_vm._v(" "),_c('span',{staticClass:"col-12 mt-3"},[_c('b',[_vm._v("Vendedor:")]),_vm._v(" "+_vm._s(item[0].seller_name))]),_vm._v(" "),_c('div',{staticClass:"col-12 sell-data"},[_c('span',[_c('b',[_vm._v(_vm._s(item[0].total_quantity))]),_vm._v("\n          "+_vm._s(item[0].total_quantity == 1 ? "Item" : "Itens")+" no valor de:\n          "),_c('b',[_vm._v(_vm._s(item[0].total_price.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })))]),_vm._v("\n          pelo valor cobrado de: "),_c('b',[_vm._v(_vm._s(item[0].price))])]),_vm._v(" "),_c('span',{staticClass:"sell-details",on:{"click":function($event){return _vm.Show(item[0])}}},[_vm._v("Ver detalhes")])])])}),_vm._v(" "),_c('div',{staticClass:"modal fade",attrs:{"id":"modalteste","tabindex":"-1","aria-labelledby":"modaltesteLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-scrollable modal-xl"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"modal-body",staticStyle:{"padding":"0"}},[_vm._l((_vm.ticket_list),function(ticket,index){return _c('div',{key:index,staticClass:"d-flex d-row justify-content-between modal-body-item",staticStyle:{"padding":"15px","height":"96px"},style:(index % 2 == 0
                  ? 'background-color:#eee'
                  : 'background-color:white')},[_c('div',{staticClass:"modal-body-details",staticStyle:{"width":"300px"}},[_c('div',{staticClass:"mb-1"},[_c('span',[_vm._v(_vm._s(ticket.ticket_name))])]),_vm._v(" "),_c('div',{staticClass:"mb-1"},[_c('span',[_c('b',[_vm._v(_vm._s(!ticket.codigo
                        ? "Código ainda não gerado"
                        : ticket.codigo.toUpperCase()))])])]),_vm._v(" "),_c('div',[_c('span',[_vm._v(_vm._s(!ticket.email
                      ? "Check-in não realizado"
                      : ticket.name + " - " + ticket.email))])])]),_vm._v(" "),_c('div',{staticStyle:{"align-self":"center","text-align":"start","padding-left":"15px"}},[_vm._v("\n                "+_vm._s("R$ " +
                  ticket.price.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }))+"\n              ")]),_vm._v(" "),_c('div',{staticStyle:{"align-self":"center","width":"300px","text-align":"end"}},[_c('span',{staticClass:"badge",class:ticket.codigo == ''
                      ? 'badge-warning'
                      : !ticket.name
                      ? 'badge-danger alter-mouse'
                      : ticket.name == '-'
                      ? 'badge-danger'
                      : 'badge-success',staticStyle:{"font-size":"medium"},on:{"click":function($event){return _vm.GoToCheckin(ticket)}}},[_vm._v(_vm._s(ticket.codigo == ""
                      ? "Aguardando Pagamento"
                      : !ticket.name
                      ? "Check-in Pendente"
                      : ticket.name == "-"
                      ? "Passaporte Cancelado!"
                      : "Check-in Feito"))])])])}),_vm._v(" "),_c('div',{staticClass:"d-flex d-row justify-content-between",staticStyle:{"padding":"15px","height":"96px","align-items":"center"},style:(_vm.ticket_list.length % 2 == 0
                  ? 'background-color:#eee'
                  : 'background-color:white')},[(_vm.ticket_list[0])?_c('span',{staticStyle:{"width":"300px"}},[_c('b',[_vm._v("Observações: ")]),_vm._v("\n                "+_vm._s(_vm.ticket_list[0].observations
                    ? _vm.ticket_list[0].observations
                    : "Não foram informadas!")+"\n              ")]):_vm._e(),_vm._v(" "),_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('b',[_vm._v("Total: ")]),_vm._v(" "+_vm._s("R$ " + _vm.ticket_list.full_price))]),_vm._v(" "),_c('span',{staticStyle:{"width":"300px"}})])],2),_vm._v(" "),_c('div',{staticClass:"modal-footer justify-content-center"},[(_vm.ticket_list[0])?_c('div',[(_vm.ticket_list[0].step == 4 || _vm.ticket_list[0].step == 5)?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.AreYouSure(false)}}},[_vm._v("\n                Cancelar Pedido\n              ")]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.ticket_list[0])?_c('div',[(_vm.ticket_list[0].step == 4)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.AreYouSure(true)}}},[_vm._v("\n                Confirmar Pedido\n              ")]):_vm._e()]):_vm._e()])])])])],2),_vm._v(" "),_c('pagination',{attrs:{"records":_vm.records,"per-page":10},on:{"paginate":_vm.callback},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title w-100",attrs:{"id":"modaltesteLabel"}},[_c('div',{staticClass:"d-flex d-row justify-content-between"},[_c('div',{staticStyle:{"width":"300px"}},[_c('span',[_c('b',[_vm._v("Detalhes dos Passaportes")])])]),_vm._v(" "),_c('div',{staticStyle:{"text-align":"start"}},[_c('span',{staticStyle:{"white-space":"nowrap","margin-left":"3em"}},[_c('b',[_vm._v("Valor Unitário")])])]),_vm._v(" "),_c('div',{staticStyle:{"width":"300px","text-align":"end"}},[_c('span',[_c('b',[_vm._v("Status do Check-in")])])])])]),_vm._v(" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
}]

export { render, staticRenderFns }